@import url('https://fonts.googleapis.com/css?family=Montserrat|Pacifico');
$mainWidth: 80%;
$title-color: #974949;
$secondary-color: #105e62;
$back-color: #edfffa;
$font-title: 'Pacifico', cursive;
$font-content: 'Montserrat', sans-serif;
$mainFont: 'Montserrat', sans-serif;

.arrow-left{
  position: fixed;
  left: 10px;
  border: transparent;
  background-color: transparent;
  font-family: $font-content;
  color: $title-color;
  font-weight: bold;
  font-size: 15px;

}

.app-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;  
}

.card {
  width:580px;
  display: flex;  
  margin: 60px;    
  box-shadow: 10px 5px 40px 20px ($title-color);
  transition: .25s;
  
  &:hover {
    box-shadow: 10px 5px 40px 20px darken ($title-color, 10%);
    cursor: pointer;
    
    .button-primary {
      transform: translate(10px, 0);
    }
  }
 
  .card-header {
    height: 300px;
    width: 100%;
    padding: 15px;
    background-size:cover;
    color:#fff;
    

    .favourite-button{
      width: 30px;
      height: 30px;
      padding: 5px;
      position: relative;
      top: 0;
      left: 220px;
      border-radius: 50%;
      background-image: url('./images/star-regular.png');
      background-repeat: no-repeat;
      background-position: center;
      background-position-y: 5px;
      background-size:30px;
      background-color: $secondary-color;
      transition: background-image 0.2s ease-out;
    }
    
    .favourite-button:hover , .favourite-button-selected {
      cursor: pointer;
      background-image: url('./images/star-hover.png');
    }
  }

  .card-body {
    padding: 15px;
    background-color:#fff;
    width:100%; 
    
    .time {
      font-size: 11px;
      font-weight: 600;
      color: grey;
    }
    .body-content {  
      font-size: 13px;
      line-height: 1.8;
    }
    .title-routeItem {
      font-family: $font-content;
      color: $title-color;
      font-size: 25px;
      margin: auto;
    }
    
    .button-primary {
      border: none;
      box-shadow: none;
      text-decoration: none;
      font-family: $font-title;
      background-color:transparent;
      color:$secondary-color;
      font-size: 20px;
      transition:.25s;
      cursor: pointer;
      
      a{
        padding-right:4px;
        text-decoration: none;
        color:$secondary-color;        
      }
  }
}
}


.content-modal {
  margin: auto;
  padding: 20px;
 
  .modal-h1 {
    color: red;
    font-family: $font-title;
  }

  .btn-content {
    display: flex;
    flex-direction: column;
    
    .modal-btn {
      align-self: center;
      font-family: $font-content;  
      background-color: $secondary-color;
      color: white;
      width: 50%;
      margin-top: 20px;
      padding: 10px 0px;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
}

@media (max-width: 500px) {
  .arrow-left{
    position: relative;
    left: -120px;
    top: 90px;
    font-size: 12px; 
  }

.app-card-list {
  margin-left: 20px;
  margin-top: 10px;
}
  .card {
    width: 288px;
    display: flex;
    flex-direction: column;
    justify-content: center;  
    margin-left: 10px; 
    margin-top: 10px;

    .card-header {
      height: 150px;

      .favourite-button{
        left: 190px;
      }
    }
    .card-body {
      .title-routeItem {
        font-size: 20px;      
      }
    }
  }
}




