@import url('https://fonts.googleapis.com/css?family=Montserrat|Pacifico');
$title-color: #974949;
$secondary-color: #105e62;
$third-color: #b5525c;
$four-color: #8bd5cb;
$back-color: #edfffa;
$font-title: 'Pacifico', cursive;
$font-content: 'Montserrat', sans-serif;

.component {    
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.component.first-component {
    height:100%;
    position: relative;
    background:
	linear-gradient(
		limegreen,
		transparent
	),
	linear-gradient(
		90deg,
		$four-color,
		transparent
	),
	linear-gradient(
		-90deg,
	    $title-color,
		transparent
	);
	
background-blend-mode: screen;
}

.first-component--inside-scroller {
    position: absolute;
    right: 20px;
    top: 100px;
}

.component.second-component {
    background-color: $four-color;
    background-image: url('./SecondComponent/images/walk2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;    
  
}

.component.third-component {
    background:
	linear-gradient(
		$four-color,
		transparent
	),
	linear-gradient(
		90deg,
		$title-color,
		transparent
	),
	linear-gradient(
		-90deg,
	    $title-color,
		transparent
	);    
}

.title-home {   
    text-align: center;
    color: $title-color;
    font-family: $font-title;
	font-size: 40px;
	margin-top: 110px;
}

@media (max-width: 500px) {
	.title-home{
		font-size: 30px;
	}
}

