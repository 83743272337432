@import url('https://fonts.googleapis.com/css?family=Montserrat|Pacifico');
$title-color: #974949;
$secondary-color: #105e62;
$third-color: #b5525c;
$four-color: #8bd5cb;
$back-color: #edfffa;
$font-title: 'Pacifico', cursive;
$font-content: 'Montserrat', sans-serif;

.form {
    box-sizing: border-box;
    max-width: 420px;
    margin: 0 auto;
}

p {
    text-align: center;
    font-weight: 500;
    font-family: $font-content;
}
input[type=text],input[type=email],input[type="password"],select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}
input[type=submit] {
    font-family: $font-title;
    background-color: $secondary-color;
    color: $back-color;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
input[type=submit]:hover {
    background-color: $third-color;
}
.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}

.btn-secondary {
    font-family: $font-content;
    background-color: transparent;
    color: black;
    padding: 5px 10px;
    margin:  auto;
    border: 2px solid $four-color;
    border-radius: 4px;
    cursor: pointer;

}