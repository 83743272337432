@import url('https://fonts.googleapis.com/css?family=Montserrat|Pacifico');
$title-color: #974949;
$secondary-color: #105e62;
$back-color: #edfffa;
$font-title: 'Pacifico', cursive;
$font-content: 'Montserrat', sans-serif;


.flipper-container {
  background-color: $back-color;
  width:80%;
  height:max-content;
  
  
  .button-map {
    border: none;
    box-shadow: none;
    text-decoration: none;
    font-family: $font-title;
    background-color:transparent;
    color:$secondary-color;
    font-size: 20px;
    transition:.25s;
      cursor: pointer;
      
      a{
        padding-right:4px;
        text-decoration: none;
        color:$secondary-color;        
      }
  }
          
  span {
    color:white;
  }
  
  div.flipper {
    width:60%;
    height:70%;
    margin: 0 20%;
    position:absolute;
    transform-style: (preserve-3d);

    .front, .back {
      display:block;	
      width:100%;
      height:100%;
      backface-visibility: hidden;
      position:absolute;
      top:0;
      left:0;
      transform-style: (preserve-3d);
      transition: (-webkit-transform ease 500ms);
      transition: (transform ease 500ms);
    }
          
    .front {
      z-index:2;
      background-color: $back-color;
      font-size: 16px;


      .title-route {
        text-align: center;
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 700;        
        }

      .desc-route{
        margin: 30px;
      }

        .ul-points {
          list-style-type: none;
          margin: 0;
          padding: 0;
        
          .points-list {
            text-decoration: none;
            padding-bottom: 10px;
            
            .map-icon {
              margin-right: 10px;
            }
          }         
      }
    }

  }
}

.flipper-container {
  .front { transform: (rotateY(0deg)); }
  .back { transform: (rotateY(-180deg)); }
        
  div.flipper.flipped {
    .front { transform:(rotateY(180deg)); }
    .back { transform: (rotateY(0deg)); }
  }
}
  
@media (max-width: 500px) {
  .flipper-container {
    div.flipper {
      width:100%;      
      margin: 0px;    
  
      .front, .back {
        display:block;	
        width:100%;
        height: 100%;
        position:absolute;
      }
      .front {
        font-size: 12px; 
  
        .title-route {
          font-size: 20px;
          font-weight: bold;        
          }
          .desc-route{
            margin: 10px;
          }
     
        }
    }
  }
}


      

