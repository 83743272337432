@import url('https://fonts.googleapis.com/css?family=Montserrat|Pacifico');
$title-color: #974949;
$secondary-color: #105e62;
$four-color: #c56868;
$back-color: #edfffa;
$font-title: 'Pacifico', cursive;
$font-content: 'Montserrat', sans-serif;

.card-profile{
    width: 80%;
    border-radius: 10px;
    background-color: $back-color;
    margin: 25px auto 0;    
    box-sizing: content-box; 
    position: relative;

  .fav-routes{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-profile {
      padding: 2px 15px;
  }

  .info-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 10px;

      .descr-profile {
          font-style: italic;
      }
      .name-profile{
        margin-bottom: 0px;
        
      }
      .descr-profile {
        margin: 10px;
      }
  }

  .image-profile {
      align-self: center;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background-color: $secondary-color;
  }

  .profile-links {    
      position: relative;
      background-color:$title-color;
      text-align: center;
      padding: 6px 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      
      .li-profile {
        text-align: center;  
        display: inline-block;
        padding: 3px 5px 0;
        
        img {
          height: 28px;
          opacity: 0.8;
          transition: all .2s ease-out;
        }
        
        a:hover img {
          opacity: 1;
          transform: scale(1.1, 1.1);
        }
      }
  }
  
  .profile-links:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: $title-color;
      border-width: 10px;
      margin-left: -10px;
    }

    .btn-submit {
        font-family: $font-title;
        background-color: $secondary-color;
        color: white;
        padding: 12px 20px;
        margin-bottom: 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    .btn-submit:hover {
        background-color:$four-color;
    }
    
    .custom-file-input {
        color: transparent;
      }
      .custom-file-input::-webkit-file-upload-button {
        visibility: hidden;
      }
      .custom-file-input::before {
        content: 'Selecciona nueva imagen';
        font-family: $font-content;
        color: black;
        display: inline-block;
        background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
        border: 1px solid #999;
        border-radius: 3px;
        padding: 5px 8px;
        margin-left: 50px;
        outline: none;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-size: 10pt;
      }
      .custom-file-input:hover::before {
        border-color: black;
      }
      .custom-file-input:active {
        outline: 0;
      }
      .custom-file-input:active::before {
        background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
      }

}

@media (max-width: 500px) {
  .card-profile{
    width: 100%;
    border-radius: 10px;
    background-color: $back-color;
    //margin: 25px auto 0;  
    margin-bottom: 20px;  
    box-sizing: content-box; 
    position: relative;

    .fav-routes{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 20px;
    }
    .image-profile {
      align-self: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: $secondary-color;
  }
  }
}





