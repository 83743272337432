@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
@import url(https://fonts.googleapis.com/css?family=Montserrat|Pacifico);
.App {
  text-align: center;
  width: 100%;
  height: 100%; }

body, html {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: auto; }

body {
  font-family: 'Montserrat', sans-serif;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #8bd5cb), color-stop(37%, #e3eaed), color-stop(100%, #c8d7dc));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #8bd5cb 0%, #e3eaed 37%, #c8d7dc 100%);
  /* Chrome10+,Safari5.1+ */
  /* Opera 11.10+ */
  /* IE10+ */
  background: linear-gradient(to bottom, #8bd5cb 0%, #e3eaed 37%, #c8d7dc 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f5f6', endColorstr='#c8d7dc',GradientType=0 );
  /* IE6-9 */
  background-repeat: no-repeat;
  background-attachment: fixed; }

.loading {
  flex-basis: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover .2s infinite  alternate; }

@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); } }

.content-mood {
  height: 400px;
  width: 700px;
  position: absolute;
  top: 60%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: block;
  color: white;
  box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1); }
  .content-mood .Curioso {
    width: 200px;
    height: 150px;
    background-image: url(/static/media/curioso.108a86c6.jpg);
    background-size: cover;
    background-position: center;
    left: 0;
    top: 0;
    position: relative;
    line-height: 150px; }
  .content-mood .Misterioso {
    position: absolute;
    height: 200px;
    width: 150px;
    left: 400px;
    top: 0px;
    background-image: url(/static/media/misterioso.ceaf7073.jpg);
    background-size: cover;
    background-position: center;
    line-height: 250px;
    color: black; }
  .content-mood .Estresado {
    position: absolute;
    height: 200px;
    width: 150px;
    left: 550px;
    top: 0px;
    background-image: url(/static/media/estresado.4b268684.jpg);
    background-size: cover;
    background-position: center;
    line-height: 250px; }
  .content-mood .Infantil {
    position: absolute;
    height: 250px;
    width: 200px;
    left: 0px;
    top: 150px;
    background-image: url(/static/media/infantil.fb4b0266.jpg);
    background-size: cover;
    background-position: center;
    line-height: 250px; }
  .content-mood .Friki {
    position: absolute;
    height: 200px;
    width: 300px;
    left: 400px;
    top: 200px;
    background-image: url(/static/media/friki.d6b3d9ed.jpg);
    background-size: cover;
    background-position: center;
    line-height: 150px; }
  .content-mood .Retro {
    position: absolute;
    height: 250px;
    width: 200px;
    left: 200px;
    top: 0px;
    background-image: url(/static/media/retro.1765cc78.jpg);
    background-size: cover;
    background-position: center;
    line-height: 250px; }
  .content-mood .Random {
    position: absolute;
    height: 150px;
    width: 200px;
    left: 200px;
    top: 250px;
    background-image: url(/static/media/random.09bcfe0a.png);
    background-size: cover;
    background-position: center;
    line-height: 150px;
    color: black; }
  .content-mood .block {
    -webkit-animation: scaledown 1s linear;
    animation: scaledown 1s linear;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
    .content-mood .block a {
      text-decoration: none;
      color: #105e62;
      font-size: 20px;
      font-weight: bold;
      border: 1px solid #edfffa;
      background-color: #edfffa; }
  .content-mood .block:hover {
    z-index: 100;
    -webkit-animation: scale 1s linear;
    animation: scale 1s linear;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1); } }

@keyframes scale {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 10px 10px 60px 10px rgba(0, 0, 0, 0.1); } }

@-webkit-keyframes scaledown {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes scaledown {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@media (max-width: 500px) {
  .content-mood {
    top: 60%;
    height: 350px;
    width: 300px; }
    .content-mood .Curioso {
      width: 150px;
      height: 100px;
      left: 0;
      top: 0;
      line-height: 50px; }
    .content-mood .Misterioso {
      height: 100px;
      width: 100px;
      left: 200px;
      top: 100px;
      line-height: 100px; }
    .content-mood .Estresado {
      height: 150px;
      width: 100px;
      left: 200px;
      top: 200px;
      line-height: 150px; }
    .content-mood .Infantil {
      height: 150px;
      width: 100px;
      left: 0px;
      top: 100px;
      line-height: 150px; }
    .content-mood .Friki {
      height: 100px;
      width: 200px;
      left: 0px;
      top: 250px;
      line-height: 100px; }
    .content-mood .Retro {
      height: 150px;
      width: 100px;
      left: 100px;
      top: 100px;
      line-height: 150px; }
    .content-mood .Random {
      height: 100px;
      width: 150px;
      left: 150px;
      top: 0px;
      line-height: 100px; }
    .content-mood .block a {
      font-size: 15px; } }

.container-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .container-2 .title-about {
    color: black;
    text-align: center;
    font-family: 'Pacifico', cursive;
    font-size: 50px;
    width: 300px;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #edfffa;
    padding: 20px;
    margin: 40px; }
  .container-2 .content-box {
    width: 50%;
    padding: 20px;
    background: rgba(255, 255, 255, 0.5);
    text-shadow: 1px 1px 1px #000;
    border: 1px solid #edfffa; }
  .container-2 .content-box p {
    color: black; }

@media (max-width: 500px) {
  .container-2 .title-about {
    font-size: 30px;
    width: 150px;
    padding: 0px;
    margin-top: 0px; }
  .container-2 .content-box {
    width: 80%;
    padding: 20px; } }

.form {
  box-sizing: border-box;
  max-width: 420px;
  margin-top: 50px; }

.return-message {
  text-align: center;
  font-weight: 500;
  font-family: "Montserrat", sans-serif; }

input[type=text], input[type=email], select, textarea {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  padding: 12px;
  border: 1px solid #edfffa;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical; }

input[type=submit] {
  font-family: "Pacifico", cursive;
  background-color: #105e62;
  color: #edfffa;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer; }

input[type=submit]:hover {
  background-color: #b5525c; }

.container {
  border-radius: 5px;
  background-color: #edfffa;
  padding: 20px; }

@media (max-width: 500px) {
  .btn-form {
    margin-top: 5px;
    margin-bottom: 10px; }
  .contact-title {
    margin: 10px; } }

.component {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column; }

.component.first-component {
  height: 100%;
  position: relative;
  background: -webkit-linear-gradient(limegreen, transparent), -webkit-linear-gradient(left, #8bd5cb, transparent), -webkit-linear-gradient(right, #974949, transparent);
  background: linear-gradient(limegreen, transparent), linear-gradient(90deg, #8bd5cb, transparent), linear-gradient(-90deg, #974949, transparent);
  background-blend-mode: screen; }

.first-component--inside-scroller {
  position: absolute;
  right: 20px;
  top: 100px; }

.component.second-component {
  background-color: #8bd5cb;
  background-image: url(/static/media/walk2.509123e6.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.component.third-component {
  background: -webkit-linear-gradient(#8bd5cb, transparent), -webkit-linear-gradient(left, #974949, transparent), -webkit-linear-gradient(right, #974949, transparent);
  background: linear-gradient(#8bd5cb, transparent), linear-gradient(90deg, #974949, transparent), linear-gradient(-90deg, #974949, transparent); }

.title-home {
  text-align: center;
  color: #974949;
  font-family: "Pacifico", cursive;
  font-size: 40px;
  margin-top: 110px; }

@media (max-width: 500px) {
  .title-home {
    font-size: 30px; } }

.toolbar {
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  background-color: #974949;
  z-index: 1;
  position: fixed; }
  .toolbar .navbar-header {
    display: flex;
    justify-content: flex-start; }
    .toolbar .navbar-header .navbar-brand {
      margin-left: 10px;
      margin-top: 0px;
      margin-right: 5px;
      padding: 10px; }
      .toolbar .navbar-header .navbar-brand .navbar-brand a {
        text-decoration: none;
        margin-left: 10px;
        margin-top: 0px;
        margin-right: 5px;
        padding: 10px; }
    .toolbar .navbar-header .name-logo {
      text-align: left;
      font-family: "Pacifico", cursive;
      font-size: 30px;
      color: #edfffa;
      text-decoration: none;
      margin-top: 15px;
      margin-left: -15px;
      padding: 10px; }
  .toolbar .ul-nav {
    list-style: none;
    margin: 10px;
    padding: 0; }
  .toolbar .li-nav {
    color: #edfffa;
    font-family: "Montserrat", sans-serif;
    margin-left: 30px;
    margin-bottom: 15px;
    padding: 0 0.5rem; }
    .toolbar .li-nav a {
      text-decoration: none;
      color: #edfffa; }
    .toolbar .li-nav .logout-btn {
      color: black;
      background-color: #edfffa;
      border: 1px solid white; }
    .toolbar .li-nav .font-icon {
      margin-right: 10px; }
  .toolbar .bm-item {
    display: inline-block;
    text-decoration: none;
    margin-bottom: 10px;
    color: #d1d1d1;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
    outline: none; }
  .toolbar .bm-item:hover {
    color: white; }
  .toolbar .bm-burger-button {
    position: fixed;
    color: white;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px; }
  .toolbar .bm-burger-bars {
    background: #edfffa; }
  .toolbar .bm-cross-button {
    height: 24px;
    width: 24px;
    margin-right: 20px; }
  .toolbar .bm-cross {
    background: #edfffa; }
  .toolbar .bm-menu {
    background: #974949;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em; }
  .toolbar .bm-morph-shape {
    fill: #974949; }
  .toolbar .bm-item-list {
    color: #b8b7ad;
    overflow-y: hidden; }
  .toolbar .bm-overlay {
    background: rgba(0, 0, 0, 0.3); }

@media (max-width: 500px) {
  .toolbar {
    width: 100%; }
    .toolbar .ul-nav {
      padding: 10px 20px;
      display: inline; }
      .toolbar .ul-nav .li-nav {
        padding: 10px 0;
        margin-left: 10px; }
    .toolbar .navbar-header .name-logo {
      font-size: 25px; }
    .toolbar .navbar-header .navbar-brand {
      width: 40px;
      margin-top: 10px; }
    .toolbar .bm-burger-button {
      width: 26px;
      height: 20px; } }

.footer {
  position: fixed;
  width: 100%;
  height: 30px;
  bottom: 0;
  left: 0;
  background: #105e62; }
  .footer p {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    font-size: 10px;
    font-weight: bold; }
  .footer .logo-foot {
    margin-right: 10px;
    margin-left: 10px; }

.form {
  box-sizing: border-box;
  max-width: 420px;
  margin: 0 auto; }

p {
  text-align: center;
  font-weight: 500;
  font-family: "Montserrat", sans-serif; }

input[type=text], input[type=email], input[type="password"], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical; }

input[type=submit] {
  font-family: "Pacifico", cursive;
  background-color: #105e62;
  color: #edfffa;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer; }

input[type=submit]:hover {
  background-color: #b5525c; }

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px; }

.btn-secondary {
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  color: black;
  padding: 5px 10px;
  margin: auto;
  border: 2px solid #8bd5cb;
  border-radius: 4px;
  cursor: pointer; }

.arrow-left {
  position: fixed;
  left: 10px;
  border: transparent;
  background-color: transparent;
  font-family: "Montserrat", sans-serif;
  color: #974949;
  font-weight: bold;
  font-size: 15px; }

.app-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.card {
  width: 580px;
  display: flex;
  margin: 60px;
  box-shadow: 10px 5px 40px 20px #974949;
  -webkit-transition: .25s;
  transition: .25s; }
  .card:hover {
    box-shadow: 10px 5px 40px 20px darken #974949, 10%;
    cursor: pointer; }
    .card:hover .button-primary {
      -webkit-transform: translate(10px, 0);
              transform: translate(10px, 0); }
  .card .card-header {
    height: 300px;
    width: 100%;
    padding: 15px;
    background-size: cover;
    color: #fff; }
    .card .card-header .favourite-button {
      width: 30px;
      height: 30px;
      padding: 5px;
      position: relative;
      top: 0;
      left: 220px;
      border-radius: 50%;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjARgKDQk1LNZzAAAC9ElEQVRo3s1ZTWsTURQ9E5pUWt0oCELUNjSKxop04UepxUURi6AgUkWhKhUEXYhIQQUpdCP9CRHFoiiSRcCNG3ddWF1kJZbSRTVkWxEb25CP5rjoxCZx3sx76Zs3ntm+e865933MnTdAwLA2E8x+nAPwzpoNxDsfsEqSrHI8CPleVlhDmYfMG0ixHm9Nyx/gWoOBNcM14Bs247VJ+Xjd/NdQ4X5zBl7RCS9Nyfew7Gigwn1mDExThBcm5GOC/NdrEPffwHO64Znf8ntYdDVQYre/BpL0QtJP+d0e+a/XoEuFM6Tk4CEinmPC8OvdyF0seOZPkkXu9acCj7BFalwE93Xnvp3HeE0yf5Is8DqPc4cMt6AlYzt6cBCxv09r+IlF+5nDF+uXqwFGEEUMCVu2e3PdoqudOXzFgpXfyLafaeaky6sLOaZ5ArA4hPcIa89WDmWcsfgZRwOSB4BPFguSm8sfrISQDVAe+B7Ck0ANTAHghPEdUMOEbYPjgcg/rqsEb9vfeaZQ5b2myeCtpm8df+XvOqwH3jRkoco7giXJKy4dry5UeMNlV/CSzxYqHPXYmLzIko/yVyXOBp5VaD1UUOQFyeOJwz5YKPK8wgnJU8xrlV/laQV5AOAgl7XJr3BIUR4AeFLb6TgoVnFry79p6wsXWzOg79rJhem/NpDQZsCFKfAKCJcZQ1hGpyYDq9hmVVUrENMmD3SIP+7EBvStAMBlEkLqIaYM6K2AkM1UBYQGRPcDYfyWuA+SRxlbrZJKBeKS8nnkpcaFIbhDFRnolSAt4Sni6MYUChKj1aaUkx4v2DWmNu5EGWXSs5mdVDOQdqGqMvXvxTy7mHT4jbGBtJqBBSHRB/YJoxJNv7LqsaAiHxHkMsMBz9gBzggmTX5XsdOhGctwWDp+mBmHietQqcFsQ/A8R6jUntHiCOcbOD6qxINH+MMOzHKMbUrBNY42jjFrsyzxsPMocT+wE6OIIoOUVWxF3mZpx2X0IYdpa6l1Fl/xB2Xhy6yOL1ngAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDE5LTAxLTI0VDA5OjEzOjA5KzAxOjAwg/UNigAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxOS0wMS0yNFQwOToxMzowOSswMTowMPKotTYAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb7jwaAAAAAElFTkSuQmCC);
      background-repeat: no-repeat;
      background-position: center;
      background-position-y: 5px;
      background-size: 30px;
      background-color: #105e62;
      -webkit-transition: background-image 0.2s ease-out;
      transition: background-image 0.2s ease-out; }
    .card .card-header .favourite-button:hover, .card .card-header .favourite-button-selected {
      cursor: pointer;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABklBMVEUAAAD/3EL/2kT/20P/20T/2kT/2kT/10T/2kT/2kT/3Eb/2kT/20T/2kT/2kT/2kT/2kT/3UX/2EH/2UP/2UT/3Ef/10P/2kX/2kP/2kT/2kT/2UX/3UT/2kT/2kT/2Eb/2kT/3Eb/10j/2kT/2UT/1kf/2kT/1kL/20T/2kT/1Uf/2kb/2UP/2kX/20T/10P/20n/20H/20X/20X/2kT/2kT/2kT/2kT/2kT/2UT/3UT/1VX/2kT/2kX/2UT/3Eb/2Dv/2kP/2kX/2Eb/2kX/2kT/2kT/2kT/2kX/20X/20T/2kP/20P/20T/2kP/2UX/2UX/2kT/2kT/2UP/20P/20P/2kT/2kT/2UL/20T/20T/2kT/2kT/20X/2kb/2kT/2kT/2UP/20D/3EP/2kT/20T//4D/2UP/2kT/2kT/2kX/20T/zDP/20P/3kP/2kX/2kT/20L/2kT/0Ub/2kT/5k3/20T//wD/2kT/2kX/2kT/2kT/20P/v0D/2kT/2EP/3Eb/2kP/2kX/2UD/2kQAAAAq0O++AAAAhHRSTlMAOog5eP51Lfz7LKqoKf2mpSUno6IkJqCfIp2cHvn6IZkdIJaVGfcfk5ISN19gOBMHK1V3u93uzGY8DwZEb14zDa7jQsnH9NjUXX99anFnZGtaYVdUW0tSSU9A8/ZGPvL1PRxIdMsCUHt8p9IFKheC2jLCC80KVgHGMO3hYwTsLhaQkRTop8KNAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+MBGAoeFUDCy64AAAJASURBVFjDtZbpW9NAEIeXanBrtIhaBTUloNSDIq3iUVvQIocgHliPcghaCh6oICKHgKLMH25ooeTYyTO70d/Hzr5vN8nsPMvY/01NKHQgCH9QA9Bq1flDHKyEDysLdCjniCp/NFIRRFS3UAe7OabG1/M9AT+uJDgB1ZxU4aPhfQE/pSA4DbY0yPONYbuA10sLzoAjZ2X5c4ZTEGuSFJjgiinHNxtuQaxFSnAePLkgw7fGvQLjooTgEghymUpfaUvERYJ4+9UOfzKZunbd7LwBPrl5Sw/dTt9xk5nGbJdFakDNjqf77r0KXZPrIYOu9OSsiZuNqeLl7rjPeoPwAH0sHkzQzwaCCR6wRDDBIGNDQfihne9Yq84/rHTCML2DHNEe7XXi44gS37Xfy08UDNpT+2kYCcvy/JnzPOUlDbzbfSKfS50J/sI7DV5KNLXxSjRPCmSDMSqeSGPjNH7iNTbTJqco/JssPhXfUnpy0mesFik7KPoIpimCaR9BiSIo+QgaKAK/28oMRTCD87P9pM84iwqiFB4gigpGaYJ3qOA9TfABFdTRBB9RwRxNMIfxnwyaIJZBBJ8Fi6dEBzSFCPKelYb5pSM04fk5jwjmXesievl6WjTdA3ceEeQcqzS9esdvMbmjlEMEC/ZFnV/tpZRury2I+YztbxbT7mp60fZs4s/wrTrPlgqiemGp+nTfxVvoq5QHllfE9ZXl3evMKvIO1n5YxfWNTYZmc2PdWvLzF1bfKv0eTDLfJBN/StvsX+Yvsw4M7wySXRcAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDEtMjRUMDk6MzA6MjErMDE6MDBBZEZSAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTAxLTI0VDA5OjMwOjIxKzAxOjAwMDn+7gAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=); }
  .card .card-body {
    padding: 15px;
    background-color: #fff;
    width: 100%; }
    .card .card-body .time {
      font-size: 11px;
      font-weight: 600;
      color: grey; }
    .card .card-body .body-content {
      font-size: 13px;
      line-height: 1.8; }
    .card .card-body .title-routeItem {
      font-family: "Montserrat", sans-serif;
      color: #974949;
      font-size: 25px;
      margin: auto; }
    .card .card-body .button-primary {
      border: none;
      box-shadow: none;
      text-decoration: none;
      font-family: "Pacifico", cursive;
      background-color: transparent;
      color: #105e62;
      font-size: 20px;
      -webkit-transition: .25s;
      transition: .25s;
      cursor: pointer; }
      .card .card-body .button-primary a {
        padding-right: 4px;
        text-decoration: none;
        color: #105e62; }

.content-modal {
  margin: auto;
  padding: 20px; }
  .content-modal .modal-h1 {
    color: red;
    font-family: "Pacifico", cursive; }
  .content-modal .btn-content {
    display: flex;
    flex-direction: column; }
    .content-modal .btn-content .modal-btn {
      align-self: center;
      font-family: "Montserrat", sans-serif;
      background-color: #105e62;
      color: white;
      width: 50%;
      margin-top: 20px;
      padding: 10px 0px;
      border: none;
      border-radius: 4px;
      cursor: pointer; }
      .content-modal .btn-content .modal-btn a {
        text-decoration: none;
        color: white; }

@media (max-width: 500px) {
  .arrow-left {
    position: relative;
    left: -120px;
    top: 90px;
    font-size: 12px; }
  .app-card-list {
    margin-left: 20px;
    margin-top: 10px; }
  .card {
    width: 288px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    margin-top: 10px; }
    .card .card-header {
      height: 150px; }
      .card .card-header .favourite-button {
        left: 190px; }
    .card .card-body .title-routeItem {
      font-size: 20px; } }

.main-modal {
  z-index: 2; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); }
  .modal .modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    min-height: 50%; }

.display-block {
  display: block; }

.display-none {
  display: none; }

.map {
  height: 450px; }

.pop-card {
  text-align: center; }

.pop-img {
  width: 200px; }

.pop-main {
  font-family: 'Montserrat', sans-serif; }

.pop-link {
  background-color: transparent; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); }
  .modal .modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.display-block {
  display: block; }

.display-none {
  display: none; }

@media (max-width: 500px) {
  .map {
    height: 300px; } }

.flipper-container {
  background-color: #edfffa;
  width: 80%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content; }
  .flipper-container .button-map {
    border: none;
    box-shadow: none;
    text-decoration: none;
    font-family: "Pacifico", cursive;
    background-color: transparent;
    color: #105e62;
    font-size: 20px;
    -webkit-transition: .25s;
    transition: .25s;
    cursor: pointer; }
    .flipper-container .button-map a {
      padding-right: 4px;
      text-decoration: none;
      color: #105e62; }
  .flipper-container span {
    color: white; }
  .flipper-container div.flipper {
    width: 60%;
    height: 70%;
    margin: 0 20%;
    position: absolute;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d; }
    .flipper-container div.flipper .front, .flipper-container div.flipper .back {
      display: block;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
      transition: -webkit-transform ease 500ms;
      -webkit-transition: -webkit-transform ease 500ms;
      transition: transform ease 500ms;
      transition: transform ease 500ms, -webkit-transform ease 500ms; }
    .flipper-container div.flipper .front {
      z-index: 2;
      background-color: #edfffa;
      font-size: 16px; }
      .flipper-container div.flipper .front .title-route {
        text-align: center;
        margin-bottom: 16px;
        font-size: 24px;
        font-weight: 700; }
      .flipper-container div.flipper .front .desc-route {
        margin: 30px; }
      .flipper-container div.flipper .front .ul-points {
        list-style-type: none;
        margin: 0;
        padding: 0; }
        .flipper-container div.flipper .front .ul-points .points-list {
          text-decoration: none;
          padding-bottom: 10px; }
          .flipper-container div.flipper .front .ul-points .points-list .map-icon {
            margin-right: 10px; }

.flipper-container .front {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg); }

.flipper-container .back {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg); }

.flipper-container div.flipper.flipped .front {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.flipper-container div.flipper.flipped .back {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg); }

@media (max-width: 500px) {
  .flipper-container div.flipper {
    width: 100%;
    margin: 0px; }
    .flipper-container div.flipper .front, .flipper-container div.flipper .back {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute; }
    .flipper-container div.flipper .front {
      font-size: 12px; }
      .flipper-container div.flipper .front .title-route {
        font-size: 20px;
        font-weight: bold; }
      .flipper-container div.flipper .front .desc-route {
        margin: 10px; } }

.card-profile {
  width: 80%;
  border-radius: 10px;
  background-color: #edfffa;
  margin: 25px auto 0;
  box-sizing: content-box;
  position: relative; }
  .card-profile .fav-routes {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .card-profile .container-profile {
    padding: 2px 15px; }
  .card-profile .info-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px; }
    .card-profile .info-header .descr-profile {
      font-style: italic; }
    .card-profile .info-header .name-profile {
      margin-bottom: 0px; }
    .card-profile .info-header .descr-profile {
      margin: 10px; }
  .card-profile .image-profile {
    align-self: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: #105e62; }
  .card-profile .profile-links {
    position: relative;
    background-color: #974949;
    text-align: center;
    padding: 6px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
    .card-profile .profile-links .li-profile {
      text-align: center;
      display: inline-block;
      padding: 3px 5px 0; }
      .card-profile .profile-links .li-profile img {
        height: 28px;
        opacity: 0.8;
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out; }
      .card-profile .profile-links .li-profile a:hover img {
        opacity: 1;
        -webkit-transform: scale(1.1, 1.1);
                transform: scale(1.1, 1.1); }
  .card-profile .profile-links:after {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #974949;
    border-width: 10px;
    margin-left: -10px; }
  .card-profile .btn-submit {
    font-family: "Pacifico", cursive;
    background-color: #105e62;
    color: white;
    padding: 12px 20px;
    margin-bottom: 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer; }
  .card-profile .btn-submit:hover {
    background-color: #c56868; }
  .card-profile .custom-file-input {
    color: transparent; }
  .card-profile .custom-file-input::-webkit-file-upload-button {
    visibility: hidden; }
  .card-profile .custom-file-input::before {
    content: 'Selecciona nueva imagen';
    font-family: "Montserrat", sans-serif;
    color: black;
    display: inline-block;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    margin-left: 50px;
    outline: none;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-size: 10pt; }
  .card-profile .custom-file-input:hover::before {
    border-color: black; }
  .card-profile .custom-file-input:active {
    outline: 0; }
  .card-profile .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); }

@media (max-width: 500px) {
  .card-profile {
    width: 100%;
    border-radius: 10px;
    background-color: #edfffa;
    margin-bottom: 20px;
    box-sizing: content-box;
    position: relative; }
    .card-profile .fav-routes {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 20px; }
    .card-profile .image-profile {
      align-self: center;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #105e62; } }

