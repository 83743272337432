@import url('https://fonts.googleapis.com/css?family=Montserrat|Pacifico');
$title-color: #974949;
$secondary-color: #105e62;
$back-color: #edfffa;
$font-title: 'Pacifico', cursive;
$font-content: 'Montserrat', sans-serif;


.container-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-about {
        color: black;    
        text-align: center;
        font-family: 'Pacifico', cursive;
        font-size: 50px;
        width: 300px;
        background-color: rgba(255,255,255, 0.8);
        border: 1px solid #edfffa;
        padding: 20px;
        margin: 40px;
    }

    .content-box {  
        width: 50%;  
        padding: 20px;
        background:rgba(255,255,255, 0.5);
        text-shadow: 1px 1px 1px #000;
        border: 1px solid $back-color;
    }

    .content-box p {
        color: black;
    }
}


@media (max-width: 500px) {
    .container-2{ 
        .title-about {
            font-size: 30px;
            width: 150px;
            padding: 0px;
            margin-top: 0px;
        }    
        .content-box {  
            width: 80%;  
            padding: 20px;
        }     
    }

}

