@import url('https://fonts.googleapis.com/css?family=Montserrat|Pacifico');

.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

body, html {
	padding: 0;
	margin: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
}

body {
  font-family: 'Montserrat', sans-serif;
    background: -moz-linear-gradient(top,  rgba(139,213,203,1) 0%, rgba(227,234,237,1) 37%, rgba(200,215,220,1) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(139,213,203,1)), color-stop(37%,rgba(227,234,237,1)), color-stop(100%,rgba(200,215,220,1))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(139,213,203,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(139,213,203,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(139,213,203,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(139,213,203,1) 0%,rgba(227,234,237,1) 37%,rgba(200,215,220,1) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f5f6', endColorstr='#c8d7dc',GradientType=0 ); /* IE6-9 */
    background-repeat:no-repeat;
    background-attachment: fixed;
}

.loading {
  flex-basis: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover .2s infinite  alternate;

  @-webkit-keyframes mover {
      0% { transform: translateY(0); }
      100% { transform: translateY(-10px); }
  }
  @keyframes mover {
      0% { transform: translateY(0); }
      100% { transform: translateY(-10px); }
  }
}
