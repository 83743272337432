@import url('https://fonts.googleapis.com/css?family=Montserrat|Pacifico');

.map {
    height: 450px;    
}

.pop-card {
    text-align: center;
}
.pop-img {
    width: 200px;    
}
.pop-main {    
    font-family: 'Montserrat', sans-serif;
}
.pop-link {
    background-color: transparent;    
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  
    .modal-main {
      position:fixed;
      background: white;
      width: 80%;
      height: auto;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
    }
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
  @media (max-width: 500px) {
    .map {
        height: 300px;    
    }  
  }